<template>
  <v-dialog
    :value="value"
    max-width="650"
    persistent
  >
    <v-card class="user-card-detail pa-sm-10 pa-3">
      <v-row>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-row>
      <v-card-title class="user-edit-title justify-center text-h5">
        {{ option.title }}
      </v-card-title>
      <v-card-text class="text-center mt-n2" v-if="option.delete">
        {{ "Por favor seleccione el item a borrar" }}
      </v-card-text>
      <v-card-text class="text-center mt-n2" v-else>
        {{ option.create ? 'Crear Nuevo Item' : 'Por favor seleccione el item a editar'}}
      </v-card-text>
      <v-alert
        v-model="alert"
        type="error"
        class="mb-0"
        dense
      >
        {{ text }}
      </v-alert>
      <v-col
        v-if="!option.create || option.delete"
        cols="12"
      >
        <v-autocomplete
          v-model="editItem"
          :items="option.selectList"
          :label="option.delete ? 'Item a borrar' : 'Item a editar'"
          :loading="loadingOne"
          hide-details
          outlined
        ></v-autocomplete>
      </v-col>

      <v-card-text class="mt-5" v-if="(editItem && !loadingOne) || option.create">
        <v-form
          class="multi-col-validation"
          @submit.prevent="submit"
          ref="form"
        >
          <v-row v-if="!option.delete && option.model !== 'third_parties'" class="justify-center">
            <v-col
              cols="12"
              md="6"
              v-if="option.model !== 'project_statuses' && option.model !== 'budget_source_types'
              && option.model !== 'budget_types' && option.model !== 'task_categories'"
            >
              <v-text-field
                v-model.trim="item.code"
                label="Código"
                @input="verifyCode"
                :autofocus="option.create"
                outlined
                dense
                :rules="[validators.required]"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="item.name_of"
                @input="verifyName"
                outlined
                dense
                label="Nombre Item"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="option.model === 'project_statuses'"
            >
              <v-select
                v-model.trim="item.type_project_status"
                :items="statusTypes"
                outlined
                dense
                label="Tipo de estado"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="option.model === 'third_parties'">
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="item.identification"
                label="Identificación"
                @input="verifyId"
                :autofocus="option.create"
                outlined
                dense
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="item.nationality"
                outlined
                dense
                label="Nacionalidad"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model.trim="item.full_name"
                @input="verifyFullName"
                outlined
                dense
                label="Nombre Completo"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="item.email"
                outlined
                dense
                label="Correo electrónico"
                :rules="[validators.emailValidator]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="item.phone"
                outlined
                dense
                label="Teléfono"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :loading="loading"
              >
                {{ option.delete ? 'Eliminar' : 'Guardar'}}
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="close"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref, reactive, inject, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import { validateForm } from '@/@core/utils'
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    options: {
      default: () => {},
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const statusTypes = [
      {
        text: 'Ideación',
        value: 'ideation',
      },
      {
        text: 'Comité',
        value: 'committe',
      },
      {
        text: 'Formulación',
        value: 'formulation',
      },
    ]
    const alert = ref(false)
    const text = ref('')
    const editItem = ref('')
    const loading = ref(false)
    const loadingOne = ref(false)
    const form = ref(null)
    const option = props.options
    const item = reactive({
      stated_at: option.delete ? false : true,
    })
    const create = inject('createItem')
    const update = inject('updateItem')
    const setOne = inject('setOneItem')

    watch(editItem, val => {
      getOne(val)
    })

    const close = () => {
      emit('input', false)
      if (form.value) form.value.reset()
      editItem.value = ''
      alert.value = false
    }

    const submit = () => {
      const isFormValid = validateForm(form)
      if (!isFormValid) return
      if (alert.value) return
      let params = {}
      const keyName = option.keyName
      if (option.delete) item.stated_at = false
      params[keyName] = item
      let payload = {
        model: option.model,
        params,
      }
      loading.value = true
      if (option.create) {
        create(payload)
          .then(res => {
            if (res) {
              loading.value = false
              Vue.nextTick(() => close())
            }
          })
          .catch(() => (loading.value = false))
      } else {
        const id = editItem.value
        payload.id = id
        update(payload).then(res => {
          if (res) {
            loading.value = false
            Vue.nextTick(() => close())
          }
        })
      }
    }

    const getOne = val => {
      const id = val
      let payload = {
        model: option.model,
        id,
      }
      loadingOne.value = true
      setOne(payload)
        .then(res => {
          console.log(res)
          Object.assign(item, res)
          loadingOne.value = false
        })
        .catch(() => (loadingOne.value = false))
    }

    const verifyCode = val => {
      text.value = `Ya existe un item con ${val} de código`
      let exist = option.selectList.find(el => el.code === val)
      if (exist) {
        alert.value = true
        option.create = false
        editItem.value = exist.value
      } else alert.value = false
    }

    const verifyId = val => {
      text.value = `Ya existe un item con ${val} de Identificación`
      let exist = option.selectList.find(item => item.identification === val)
      if (exist) {
        alert.value = true
        option.create = false
        editItem.value = exist.value
      } else alert.value = false
    }

    const verifyName = val => {
      text.value = `Ya existe un item con ${val} de nombre`
      let exist = option.selectList.find(item => {
        if (item.text && val) {
          return item.text.toLowerCase() === val.toLowerCase()
        } else return false
      })
      if (exist) {
        alert.value = true
        option.create = false
        editItem.value = exist.value
      } else alert.value = false
    }

    const verifyFullName = val => {
      text.value = `Ya existe un item con ${val} de nombre`
      let exist = option.selectList.find(item => {
        if (item.full_name && val) {
          return item.full_name.toLowerCase() === val.toLowerCase()
        } else return false
      })
      if (exist) {
        alert.value = true
        option.create = false
        editItem.value = exist.value
      } else alert.value = false
    }

    return {
      alert,
      close,
      editItem,
      form,
      getOne,
      loading,
      loadingOne,
      item,
      option,
      statusTypes,
      submit,
      text,
      verifyCode,
      verifyId,
      verifyFullName,
      verifyName,
      icons: {
        mdiClose,
      },
      validators: {
        emailValidator,
        required,
      },
    }
  },
}
</script>

